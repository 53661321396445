import React, { useEffect } from "react";
import LineaVivenciasSelector from "./LineaVivenciasSelector";
import "./SessionAttributesModal.css";

const SessionAttributesModal = ({
  isOpen,
  onClose,
  onSave,
  sessionName,
  setSessionName,
  sessionDate,
  setSessionDate,
  lineaVivencias,
  handleGradoChange,
  handleDisableLinea,
  title,
}) => {
  useEffect(() => {
    if (isOpen && sessionName === "") {
      // Set default values when the modal opens if sessionName is empty
      // Adjust logic here if you need to set other default values
      setSessionName(""); 
      setSessionDate(""); 
    }
  }, [isOpen, sessionName, setSessionName, setSessionDate]);

  if (!isOpen) return null;

  const handleSave = () => {
    onSave({
      name: sessionName,
      primera_fecha: sessionDate,
      linea_vivencias: lineaVivencias,
    });
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <form>
          <label htmlFor="session-name">Session Name:</label>
          <input
            type="text"
            id="session-name"
            value={sessionName || ""}
            onChange={(e) => setSessionName(e.target.value)}
            required
          />

          <label htmlFor="session-date">Session Date:</label>
          <input
            type="date"
            id="session-date"
            value={sessionDate || ""}
            onChange={(e) => setSessionDate(e.target.value)}
            required
          />

          <h3>Linea Vivencias</h3>
          {lineaVivencias.length === 0 ? (
            <p>Loading Linea Vivencias...</p>
          ) : (
            <LineaVivenciasSelector
              lineaVivencias={lineaVivencias}
              onGradoChange={handleGradoChange}
              onDisableLinea={handleDisableLinea}
            />
          )}

          <div className="modal-actions">
            <button type="button" onClick={handleSave}>
              Update Session Attributes
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SessionAttributesModal;
