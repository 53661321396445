import React from "react";
import useSessionModalState from "./useSessionModalState";
import SessionAttributesModal from "./SessionAttributesModal";

const SessionEditionModal = ({
  isOpen,
  onClose,
  onSave,
  initialSession,
}) => {
  const {
    sessionName,
    setSessionName,
    sessionDate,
    setSessionDate,
    lineaVivencias,
    handleGradoChange,
    handleDisableLinea,
  } = useSessionModalState({
    isOpen,
    initialSession,
  });

    // Function to handle saving the session attributes
    const saveSessionAttributes = () => {
        const updatedSession = {
        name: sessionName,
        primera_fecha: sessionDate,
        linea_vivencias: lineaVivencias,
        };
        onSave(updatedSession);
    };

  return (
    <SessionAttributesModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={saveSessionAttributes}  // Use saveSessionAttributes here
      sessionName={sessionName}
      setSessionName={setSessionName}
      sessionDate={sessionDate}
      setSessionDate={setSessionDate}
      lineaVivencias={lineaVivencias}
      handleGradoChange={handleGradoChange}
      handleDisableLinea={handleDisableLinea}
      title="Edit Session"
    />
  );
};

export default SessionEditionModal;
