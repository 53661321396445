import { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';

const useSessionModalState = ({ isOpen, initialSession = null }) => {
  const { authFetch } = useAuth();
  const [sessionName, setSessionName] = useState(initialSession ? initialSession.name : "");
  const [sessionDate, setSessionDate] = useState(initialSession ? initialSession.primera_fecha : "");
  const [lineaVivencias, setLineaVivencias] = useState(initialSession ? initialSession.linea_vivencias : []);

  // Fetch Linea Vivencias if no initial session is provided
  useEffect(() => {
    const fetchLineaVivencias = async () => {
      try {
        const data = await authFetch('/sesiones/api/linea_vivencias/');
        const vivenciasWithGrado = data.map((option) => ({
          ...option,
          grado_asociacion: 0,
        }));
        setLineaVivencias(vivenciasWithGrado);
      } catch (error) {
        console.error('Error fetching LineaVivencias:', error);
      }
    };

    if (isOpen && !initialSession) {
      // Fetch default data when no initial session
      fetchLineaVivencias();
    } else if (isOpen && initialSession) {
      // If editing, use initialSession data
      setSessionName(initialSession.name || "");
      setSessionDate(initialSession.primera_fecha || "");
      setLineaVivencias(initialSession.linea_vivencias || []);
    }
  }, [isOpen, initialSession, authFetch]); // Trigger effect when modal opens or initial session changes

  const handleGradoChange = (index, grado) => {
    const updatedVivencias = lineaVivencias.map((vivencia, i) => {
      if (i === index) {
        return { ...vivencia, grado_asociacion: grado };
      }
      return vivencia;
    });
    setLineaVivencias(updatedVivencias);
  };

  const handleDisableLinea = (index) => {
    handleGradoChange(index, 0);
  };

  return {
    sessionName,
    setSessionName,
    sessionDate,
    setSessionDate,
    lineaVivencias,
    handleGradoChange,
    handleDisableLinea,
  };
};

export default useSessionModalState;
