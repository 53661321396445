import React from "react";
import useSessionModalState from "./useSessionModalState";
import SessionAttributesModal from "./SessionAttributesModal";

const SessionCreationModal = ({ 
  isOpen, 
  onClose, 
  onSave 
}) => {
  const {
    sessionName,
    setSessionName,
    sessionDate,
    setSessionDate,
    lineaVivencias,
    handleGradoChange,
    handleDisableLinea,
  } = useSessionModalState({ isOpen });

  return (
    <SessionAttributesModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      sessionName={sessionName}
      setSessionName={setSessionName}
      sessionDate={sessionDate}
      setSessionDate={setSessionDate}
      lineaVivencias={lineaVivencias}
      handleGradoChange={handleGradoChange}
      handleDisableLinea={handleDisableLinea}
      title="Create New Session"
    />
  );
};

export default SessionCreationModal;
