import React from "react";
import "./LineaVivenciasSelector.css";

const LineaVivenciasSelector = ({
  lineaVivencias,
  onGradoChange,
  onDisableLinea,
}) => {
  const renderStars = (grado, index) => {
    return [...Array(5)].map((_, starIndex) => (
      <span
        key={starIndex}
        className={grado > starIndex ? "star-filled" : "star-empty"}
        onClick={() => onGradoChange(index, starIndex + 1)}
        style={{ cursor: "pointer", fontSize: "20px" }}
      >
        ●
      </span>
    ));
  };

  return (
    <table className="linea-vivencia-table">
      <thead>
        <tr>
          <th>Linea Vivencia</th>
          <th>Rating</th>
          <th>Action</th>
        </tr>
      </thead>
      <>
            {    console.log(lineaVivencias)}
            </>
      <tbody>
        {lineaVivencias.map((vivencia, index) => (
          <tr key={vivencia.id} className="linea-vivencia-entry">
            <td>{vivencia.name}</td>
            <td>
              <div className="stars">
                {renderStars(vivencia.grado_asociacion, index)}
              </div>
            </td>
            <td>
              {vivencia.grado_asociacion > 0 && (
                <button
                  type="button"
                  onClick={() => onDisableLinea(index)}
                  style={{
                    color: "red",
                    background: "none",
                    border: "none",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  &#10006;
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LineaVivenciasSelector;
